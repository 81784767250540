<template>
  <span>
    <b-row>
      <b-col>
        <h2>Notes</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <quill-editor
            style="height: 100vh"
            id="editor"
            v-model="formData.notes"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <br />
    <br />

    <b-row>
      <b-col>
        <h2>Terms &amp; Conditions</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group>
          <quill-editor
            style="height: 100vh"
            id="editor"
            v-model="formData.tnc"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <br />
    <br />
  </span>
</template>

<script>
export default {
  name: 'SupplierInOutNotesTnCSection',
  props: {
    formData: {
      type: Object,
      default: {},
    },
  },
  components: {
    //
  },
  mounted() {
    this.formData.tnc = this.$store.getters['hbaseGeneral/getTermsCondition'];
  },
};
</script>

<style>
#editor {
  height: 150px !important;
  margin-bottom: 14px !important;
}
</style>

<template>
  <span>
    <b-row>
      <b-col>
        <h2>Billing Address</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="4">
        <b-form-group label="Attn" label-for="v-billing-attn">
          <b-form-input v-model="formData.billing_name" id="v-billing-attn" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Company" label-for="v-billing-company">
          <b-form-input
            v-model="formData.billing_company"
            id="v-billing-company"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Email" label-for="v-billing-email">
          <validation-provider
            #default="{ errors }"
            name="Billing Email"
            rules="required|email"
          >
            <b-form-input
              :class="errors.length > 0 ? 'is-invalid' : null"
              :state="errors.length > 0 ? false : null"
              v-model="formData.billing_email"
              id="v-billing-email"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group
          label="Building/Block/House No. and Street Name"
          label-for="v-billing-addr1"
        >
          <b-form-input v-model="formData.billing_addr1" id="v-billing-addr1" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group
          label="Unit No. and Building Name"
          label-for="v-billing-addr2"
        >
          <b-form-input v-model="formData.billing_addr2" id="v-billing-addr2" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label="Telephone No." label-for="v-billing-tel">
          <b-form-input v-model="formData.billing_tel" id="v-billing-tel" />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Fax" label-for="v-billing-fax">
          <b-form-input v-model="formData.billing_fax" id="v-billing-fax" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label="Country" label-for="v-billing-country">
          <v-select
            v-model="formData.billing_country"
            :options="$store.getters['hbaseGeneral/getAllCountries']"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Postal Code" label-for="v-billing-postal">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="formData.billing_postal"
              id="v-billing-postal"
            />
            <b-input-group-append is-text @click="isShowingMap = !isShowingMap">
              <feather-icon class="pin" icon="MapPinIcon" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <ModalMap :showMap="isShowingMap" @onChangePlace="(v) => getPlace(v)" />
  </span>
</template>

<script>
import vSelect from 'vue-select';
import ModalMap from '@/views/components/general/Map';

export default {
  name: 'SupplierInOutBillingSection',
  props: {
    formData: {
      type: Object,
      default: {},
    },
  },
  components: {
    vSelect,
    ModalMap,
  },
  data: () => ({
    isShowingMap: false,
  }),
  methods: {
    getPlace(place) {
      this.formData.billing_addr1 = place.name;
      this.formData.billing_country = place.country;
      this.formData.billing_postal = place.postal;
      // this.isShowingMap = false;
    },
  },
};
</script>

<style>
.pin:hover {
  cursor: pointer;
}
</style>

<template>
  <span>
    <DxGrid
      :isEditable="false"
      :data-source="dataSource"
      @on-delete="(v) => onDelete(v)"
      @on-open-modal="(v) => (modalAction = v)"
    >
      <template #columns>
        <DxColumn data-field="name" caption="Name" />
        <DxColumn data-field="filename" caption="File Name" />
      </template>
    </DxGrid>

    <b-row v-if="filePreview && isImage">
      <b-col>
        <b-form-group>
          <b-img width="250" center thumbnail fluid :src="filePreview" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="filePreview && !isImage">
      <b-col class="text-center">
        <b-form-group>
          <feather-icon icon="FileTextIcon" size="72" />
        </b-form-group>
      </b-col>
    </b-row>

    <span v-if="modalAction">
      <validation-observer
        ref="refSupplierInOutDocuments"
        #default="{ invalid }"
      >
        <b-row>
          <b-col cols="12">
            <b-form-group label="Upload" label-for="v-upload">
              <validation-provider
                #default="{ errors }"
                name="File"
                rules="required"
              >
                <b-form-file
                  :class="errors.length > 0 ? 'is-invalid' : null"
                  :state="errors.length > 0 ? false : null"
                  v-model="fileUpload"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  id="fileUpload"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-right">
            <b-button
              @click="onCloseForm()"
              variant="danger"
              class="bg-darken-4 mr-1"
            >
              Cancel
            </b-button>
            <b-button
              :disabled="invalid"
              @click="onSubmit"
              variant="primary"
              class="bg-darken-4"
            >
              Save
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </span>
  </span>
</template>

<script>
import rfqDocApi from '@api/rfq_documents';
import { required } from '@validations';
import { queryParameters } from '@/schema';

export default {
  name: 'SupplierInOutDocumentsTab',
  components: {},
  data: () => ({
    required,
    modalAction: null,
    dataSource: [],
    dataColumns: [],

    fileUpload: null,
    filePreview: null,
    fileName: null,
    fileExt: null,

    isImage: false,
  }),
  watch: {
    modalAction(v) {
      return v.value;
    },
    fileUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.fileExt = file.name.split('.')[1];
          this.fileName = file.name;
          this.filePreview = reader.result;
        };

        this.isImage = file.type.includes('image');
      }
    },
  },
  mounted() {
    if (+this.$route.query.id) {
      this.loadRfqDocuments();
    }
  },
  methods: {
    loadRfqDocuments() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.filterExt = `header_id=${this.$route.query.id}`;

      rfqDocApi
        .list(params)
        .then(({ data }) => {
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },

    onCloseForm() {
      this.modalAction = false;

      this.fileUpload = null;
      this.filePreview = null;
      this.fileName = null;
      this.fileExt = null;

      this.isImage = false;
    },
    onDelete(data) {
      rfqDocApi
        .delete(data)
        .then(() => {
          this.loadRfqDocuments();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const data = {
        id: this.id,
        header_id: +this.$route.query.id,
        name: this.fileName,
        remarks: this.fileName,
        filename: `${new Date().getTime()}.${this.fileExt}`,
        filenamebase64: this.filePreview,
        status: 'O',
      };

      if (+this.$route.query.id) {
        rfqDocApi
          .add(data)
          .then(({ data }) => {
            this.onCloseForm();
            this.loadRfqDocuments();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
  },
};
</script>

<style></style>
